var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cluji-card',{attrs:{"title":"Calendriers ICS & Google","subtitle":"Ajoutez les calendriers du Cluji à votre agenda","starts-open":false}},[_c('h2',[_vm._v("Format ICS")]),_c('p',[_vm._v("Vous pouvez intégrer les calendriers du cluji à votre agenda à partir des URLs suivantes :")]),_c('table',[_c('tbody',_vm._l((_vm.icsLinks),function(icsLink){return _c('tr',{key:icsLink.key},[_c('td',{staticClass:"pb-2"},[_c('span',{staticClass:"prepend-link",style:({
                        '--prepend-color' : icsLink.color.border,
                        '--prepend-background' : icsLink.color.background + '20'
                        })},[_c('font-awesome-icon',{attrs:{"icon":icsLink.icon}}),_vm._v(" "+_vm._s(icsLink.title)+" ")],1)]),_c('td',{staticClass:"pb-2"},[_c('a',{attrs:{"href":'/ics/' + icsLink.baseURI + '.' + icsLink.key + '.ics',"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getAbsoluteUrl('/ics/' + icsLink.baseURI + '.' + icsLink.key + '.ics'))+" ")])])])}),0)]),_c('hr'),_c('h2',[_vm._v("Google Agenda")]),_c('p',[_vm._v("Vous pouvez également visualiser ces calendriers sur Google Agenda :")]),_vm._l((_vm.googleLinks),function(googleLink){return _c('a',{key:googleLink.url,staticClass:"google-link",style:({
                '--prepend-color' : googleLink.color.border,
                '--prepend-background' : googleLink.color.background + '20'
                }),attrs:{"href":googleLink.url,"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":googleLink.icon}}),_vm._v(" "+_vm._s(googleLink.title)+" ")],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }