<template>
    <cluji-card title="Calendriers ICS & Google"
                subtitle="Ajoutez les calendriers du Cluji à votre agenda"
                :starts-open="false">
        <h2>Format ICS</h2>
        <p>Vous pouvez intégrer les calendriers du cluji à votre agenda à partir des URLs suivantes :</p>
        <table>
            <tbody>
            <tr v-for="icsLink in icsLinks" :key="icsLink.key">
                <td class="pb-2">
                    <span class="prepend-link"
                          :style="{
                            '--prepend-color' : icsLink.color.border,
                            '--prepend-background' : icsLink.color.background + '20'
                            }">
                        <font-awesome-icon :icon="icsLink.icon"/>
                        {{ icsLink.title }}
                    </span>
                </td>
                <td class="pb-2">
                    <a :href="'/ics/' + icsLink.baseURI + '.' + icsLink.key + '.ics'" target="_blank">
                        {{ getAbsoluteUrl('/ics/' + icsLink.baseURI + '.' + icsLink.key + '.ics') }}
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
        <hr/>
        <h2>Google Agenda</h2>
        <p>Vous pouvez également visualiser ces calendriers sur Google Agenda :</p>
        <a v-for="googleLink in googleLinks" :key="googleLink.url" :href="googleLink.url" target="_blank"
           class="google-link"
           :style="{
                    '--prepend-color' : googleLink.color.border,
                    '--prepend-background' : googleLink.color.background + '20'
                    }">
            <font-awesome-icon :icon="googleLink.icon"/>
            {{ googleLink.title }}
        </a>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import('@/components/ClujiCard');

    import colors from "@/util/colors";

    export default {
        name: "CalendarLinksCard",
        components: {ClujiCard},
        data: () => ({
            icsLinks: [
                {
                    icon: 'calendar-day',
                    title: 'Événements',
                    color: colors.evenements,
                    baseURI: 'evenements',
                    key: 'd1a74d92'
                },
                {
                    icon: 'place-of-worship',
                    title: 'Utilisation du local',
                    color: colors.local,
                    baseURI: 'local',
                    key: 'f39e0f8d'
                },
                {
                    icon: 'scroll',
                    title: 'Emprunts de jeux/livres',
                    color: colors.jeux,
                    baseURI: 'emprunts',
                    key: 'fbe4afe7'
                },
                {
                    icon: ['far', 'calendar-alt'],
                    title: 'Permanences',
                    color: colors.permanences,
                    baseURI: 'permanences',
                    key: '1d01188d'
                }
            ],
            googleLinks: [
                {
                    icon: 'calendar-day',
                    title: 'Événements',
                    color: colors.evenements,
                    url: 'https://calendar.google.com/calendar/embed?src=l7t13uc26qvpul91nlnnqtcd0diuoc5s%40import.calendar.google.com&ctz=Europe%2FParis'
                },
                {
                    icon: 'place-of-worship',
                    title: 'Utilisation du local',
                    color: colors.local,
                    url: 'https://calendar.google.com/calendar/embed?src=fnv447r7b6vlh31pr1nucrpveosr59v2%40import.calendar.google.com&ctz=Europe%2FParis'
                },
                {
                    icon: 'scroll',
                    title: 'Emprunts de jeux/livres',
                    color: colors.jeux,
                    url: 'https://calendar.google.com/calendar/embed?src=97ajcuh8tlbgdgq2fn8o248f4m2t1fab%40import.calendar.google.com&ctz=Europe%2FParis'
                },
                {
                    icon: ['far', 'calendar-alt'],
                    title: 'Permanences',
                    color: colors.permanences,
                    url: 'https://calendar.google.com/calendar/embed?src=228u2mb1itpcpstou3m65g9b617a2pq1%40import.calendar.google.com&ctz=Europe%2FParis'
                }
            ]
        }),
        methods: {
            getAbsoluteUrl(path) {
                return new URL(path, document.baseURI).href;
            }
        }
    }
</script>

<style scoped>
    .prepend-link, .google-link {
        border: 1px solid var(--prepend-color);
        color: var(--prepend-color);
        background-color: var(--prepend-background);
        border-radius: 0.375rem;
        margin-right: 1rem;
        padding: 5px;
    }

    .google-link:hover {
        filter: drop-shadow(0 0 0.02rem var(--prepend-color));
    }
</style>